<template>
	<div class="contact-item" :class="{hours: isContact }">
		<base-svg :name="icon"/>
		<base-font weight="bold" uppercase class="contact-item-title">{{title}}</base-font>
		<base-link :url="link" v-if="type !== 'text'" class="contact-item-content">
			{{content}}
		</base-link>
		<base-font v-else weight="medium" :class="{blue : isBlue }" class="contact-item-content" :html="content"/>
	</div>
</template>
<script>
export default {
	props: {
		icon: String,
		title: String,
		type: {
			type: String,
			default: 'text'
		},
		content: String,
		isBlue: Boolean,
		isContact: Boolean
	},
	computed: {
		link () {
			return this.type === 'mail' ? `mailto:${this.content}` : `tel:${this.content.replace(/\s/g, '')}`
		}
	}
}
</script>
<style lang="scss" scoped>

	.contact-item {
        display: grid;
		grid-template-columns: 30px minmax(95px, max-content) 1fr;
		gap: 2rem;
		place-items: center start;
        &.hours {
            grid-template-columns: 0px minmax(95px, max-content) 1fr;
            gap: 0;
            color: $secondary;
			::v-deep .contact-item-title {
				margin-right: .5rem;
			}
        }
		@include media-breakpoint-up(lg) {
			gap: 1.5rem;
		}
		@include media-breakpoint-up(xl) {
			gap: 3rem;
		}
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		::v-deep .svg-icon {
			height: 2rem;
			width: 2rem;
			grid-column: 1;
			@include media-breakpoint-up(lg) {
				height: 2.5rem;
				width: 2.5rem;
			}
			path {
				fill: $secondary;
			}
		}
		::v-deep &-title {
			grid-column: 2;
		}
		::v-deep &-content {
			grid-column: 3;
		}
        ::v-deep .blue {
            color: $secondary;
            font-weight: bold;
            text-align: center;
        }
	}

</style>
