<template>
	<section class="section-wrapper">
		<base-heading heading="contact_sales_department.section_sales_title" subheading="contact_sales_department.section_sales_subtitle" v-animate="`slideLeft`"/>
		<grid-template cols="3">
			<contact-details-sales-item
				v-for="(element, index) in data.details"
				:key="element.fullName"
				:element="{...element, type: 'details', index:index}"
                v-animate="`opacity${index}`"
				/>
        </grid-template>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import ContactDetailsSalesItem from '../../molecules/ContactDetailsItem/ContactDetailsSalesItem.vue'
import BaseHeading from '../../molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	components: {
		BaseHeading,
		ContactDetailsSalesItem,
		GridTemplate
	},
	props: {
		data: Object
	}
}
</script>
<style lang="scss" scoped>
	.section-wrapper {
		padding: 4rem 0 0 0;

		@include media-breakpoint-up(xl) {
			padding: 8rem 0 0 0;
		}

        ::v-deep .grid-wrapper {
            &.last {
                margin-bottom: 0;
            }
        }
	}
</style>
