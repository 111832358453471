<template>
	<div class="section-contact-wrapper">
		<section class="contact-main" v-background="backgroundOverlay">
		<base-container>
			<base-heading tag="h1" heading="contact-form.title" subheading="contact-form.under_title_text" :layout="true" v-animate="`slideLeft`" />
				<div class="wrapper">
					<div class="company-info" v-animate="`slideLeft`">
						<div class="company-details">
							<cms-text value="contact_page.contactDataTitle" :props="{variant: 'medium-heading'}"/>
							<base-svg name="logo_color" class="logo-icon"/>
							<contact-item icon="home" :title="translate.owner" :content="settingsLangs.owner"/>
							<contact-item icon="pin" :title="translate.headquaters" :content="headquaters"/>
							<contact-item icon="label" :title="translate.nip" :content="settingsLangs.nip"/>
							<contact-item icon="paperology" :title="translate.regon" :content="settingsLangs.regon"/>
							<contact-item icon="info" :title="translate.krs" :content="settingsLangs.krs"/>
						</div>
					</div>
                    <div class="contact-desc" v-animate="`slideLeft`">
                        <div class="contact-details">
							<cms-text value="contact_page.questionTitle" class="contact-heading" :props="{variant: 'medium-heading'}"/>
						</div>
                        <div class="numbers">
                            <div class="number-wrapper">
                                <base-link class="number-link" :url='`tel:${settingsLangs.phoneNumber}`'>
                                    <base-svg class="svg-phone" name="phone"/>
                                    <base-font class="number-text" variant="number">
                                        {{settingsLangs.phoneNumber}}
                                    </base-font>
                                </base-link>
                            </div>
                            <div class="number-wrapper">
                                <base-link class="number-link" :url='`mailto:${settingsLangs.email}`'>
                                    <base-svg class="svg-phone" name="mail"/>
                                    <base-font class="number-text" variant="number">
                                        {{settingsLangs.email}}
                                    </base-font>
                                </base-link>
                            </div>
                        </div>
                        <div class="open-details">
							<cms-text value="contact_page.answerTitle" class="contact-heading" :props="{variant: 'medium-heading'}"/>
							<contact-item isContact isBlue :title="`${translate.weekdays}: `" :content="` ${pageLangs.weekdayHours}`"/>
						</div>
                    </div>
				</div>
		</base-container>
		</section>
        <cta-contact-section />
        <base-container>
		<contact-details-sales :data="salesDepartment"/>
		<contact-details :data="propsLangs"/>
        </base-container>
	</div>
</template>
<script>
import ContactItem from '~/website/front/components/molecules/ContactItem/ContactItem.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import ContactDetails from '~/website/front/components/sections/ContactDetails/ContactDetails.vue'
import ContactDetailsSales from '~/website/front/components/sections/ContactDetails/ContactDetailsSales.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CtaContactSection from '~/website/front/sections/CtaContactSection/CtaContactSection.vue'

export default {
	components: {
		ContactItem,
		ContactDetails,
		ContactDetailsSales,
		CtaContactSection,
		BaseHeading
	},
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('contact')
		},
		headquaters () {
			return `${this.settingsLangs.streetAddress}, ${this.settingsLangs.postalCode} ${this.settingsLangs.addressLocality}`
		},
		settingsLangs () {
			return this.$app.settings.general.contact
		},
		pageLangs () {
			return this.$app.page.value.contact_page
		},
		propsLangs () {
			return this.$app.page.value.contact_page_extended
		},
		salesDepartment () {
			return this.$app.page.value.contact_sales_department
		},
		backgroundOverlay () {
			const path = '/products/bg-overlay.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 1024, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.contact-main {
    padding: 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}
	background-size: cover;
}
.contact-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
        width: 70%;
    }
    @include media-breakpoint-up(xl) {
        width: 60%;
    }
    @include media-breakpoint-up(xxl) {
        width: 70%;
    }
}
.section-contact-wrapper {
	padding: 4rem 0 0rem 0;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0 0rem 0;
	}
	.wrapper {
		display: flex;
		gap: 2rem;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
        text-align: center;

		@include media-breakpoint-up(md) {
			flex-direction: row;
            text-align: left;
            align-items: flex-start;
		}

	}
	.logo-icon {
        width: 210px;
        margin-block: 0 2rem;
        @include media-breakpoint-up(md) {
            width: initial;
            margin-block: 2rem 4rem;
        }
	}
	.company-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 2rem;
		@include media-breakpoint-up(xl) {
			gap: 4rem;
		}
	}
    .numbers {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 2rem;
        margin: 0 0 2rem 0;
        @include media-breakpoint-up(md) {
            margin: 2rem 0;
        }
		@include media-breakpoint-up(xl) {
			position: relative;
			width: 100%;
            justify-content: space-around;
            margin-bottom: 6rem;
			.number-link {
				::v-deep .number-text {
					@include transition;
				}
			}
			.number-link:hover {
				::v-deep .number-text {
					color: $secondary;
				}
			}
		}
		.number-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
            ::v-deep .number-text {
                text-transform: initial;
            }

		}
		.svg-phone {
			width: 35px;
			height: 35px;
			margin-right: 1rem;
			& ::v-deep path {
				fill: $secondary;
			}
		}
	}
}
.open-details {
    text-align: center;
}
</style>
