<template>
	<div class="detail-card">
		<cms-text :props="{variant:'small-heading', marginReset: true, color: 'gray'}" :value="`contact_page_extended.${element.type}.${element.index}.name`"/>
		<cms-text :props="{size:'xs', weight: 'bold', color:'secondary'}" v-if="element.position" :value="`contact_page_extended.${element.type}.${element.index}.position`"/>
		<div v-if="element.phoneRepeater">
			<base-link class="detail-link"
				v-for="(item, index) in element.phoneRepeater"
				:key="index"
				:url="`tel:${item.phone}`"
			>
				<cms-text
					:props="{size:'xs', weight:'medium' , color:'secondary', tag:'p'}"
					:value="`contact_page_extended.${element.type}.${element.index}.phoneRepeater.${index}.phone`"
				/>
			</base-link>
			<div v-if="element.extension_number" class="extension-number">
			</div>
		</div>
		<div class="wrapper" v-if="element.emailRepeater">
			<base-link class="detail-link"
				v-for="(item, index) in element.emailRepeater"
				:key="item.email"
				:url="`mailto:${item.email}`"
				>
				<cms-text
					:props="{size:'xs', weight:'medium', color: 'gray', tag:'p'}"
					:value="`contact_page_extended.${element.type}.${element.index}.emailRepeater.${index}.email`"
				/>
			</base-link>
		</div>
		<div class="wrapper" v-if="element.additionalDetails">
			<base-link
				v-for="(item, index) in element.additionalDetails"
				:key="item.name"
			>
				<cms-text
					:props="{size:'xs', weight:'medium', color:'secondary'}"
					:value="`contact_page_extended.${element.type}.${element.index}.additionalDetails.${index}.detail`"
				/>
			</base-link>
		</div>
	</div>
</template>
<script>

export default {
	props: {
		element: Object
	},
	data () {
		return {
			dataName: this.element.fullName
		}
	}
}
</script>
<style lang="scss" scoped>
.detail-card {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
    @include media-breakpoint-up(md) {
        padding: 2rem;
    }
	.profile-wrapper {
		::v-deep .svg-icon.man {
			path {
				fill: $secondary;
				opacity: .5;
			}
		}
        ::v-deep .svg-icon {
            width: 100px;
            height: 75px;
            @include media-breakpoint-up(lg) {
                width: 200px;
                height: 150px;
            }
        }
	}
	.extension-number {
		display: flex;
		align-items: baseline;
		justify-content: center;
		gap: 5px;
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>
